exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-dna-result-js": () => import("./../../../src/pages/account/dna-result.js" /* webpackChunkName: "component---src-pages-account-dna-result-js" */),
  "component---src-pages-account-dna-submitted-js": () => import("./../../../src/pages/account/dna-submitted.js" /* webpackChunkName: "component---src-pages-account-dna-submitted-js" */),
  "component---src-pages-account-dna-upload-js": () => import("./../../../src/pages/account/dna-upload.js" /* webpackChunkName: "component---src-pages-account-dna-upload-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-lifestyle-recommendation-js": () => import("./../../../src/pages/account/lifestyle-recommendation.js" /* webpackChunkName: "component---src-pages-account-lifestyle-recommendation-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dna-profile-js": () => import("./../../../src/pages/dna-profile.js" /* webpackChunkName: "component---src-pages-dna-profile-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-supplements-js": () => import("./../../../src/pages/supplements.js" /* webpackChunkName: "component---src-pages-supplements-js" */),
  "component---src-pages-survey-basic-info-js": () => import("./../../../src/pages/survey/basic-info.js" /* webpackChunkName: "component---src-pages-survey-basic-info-js" */),
  "component---src-pages-survey-index-js": () => import("./../../../src/pages/survey/index.js" /* webpackChunkName: "component---src-pages-survey-index-js" */),
  "component---src-pages-survey-recommendation-js": () => import("./../../../src/pages/survey/recommendation.js" /* webpackChunkName: "component---src-pages-survey-recommendation-js" */),
  "component---src-pages-survey-result-js": () => import("./../../../src/pages/survey/result.js" /* webpackChunkName: "component---src-pages-survey-result-js" */),
  "component---src-pages-survey-supplement-js": () => import("./../../../src/pages/survey/supplement.js" /* webpackChunkName: "component---src-pages-survey-supplement-js" */),
  "component---src-pages-survey-welcome-back-js": () => import("./../../../src/pages/survey/welcome-back.js" /* webpackChunkName: "component---src-pages-survey-welcome-back-js" */)
}

